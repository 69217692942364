<template>
  <div id="container" class="gnb_pdt">
    <div class="title_wrap"><h1>서비스신청관리</h1></div>
    <common-area-search
        :row="3"
        :colspan="2"
        @search="commonOnChangeEvent"
        @clear="cleanSearchDataEvent"
    >
      <template v-slot:R1_C1>
        <label>회사명</label>
        <ul>
          <input
              ref="cmpyNmRef"
              :placeholder="'회사명'"
              class="k-textbox w100"
              @keyup.enter="commonOnChangeEvent"
          />
        </ul>
      </template>
      <template v-slot:R1_C2>
        <label>신청일자 </label>
        <ul>
          <common-range-datepicker
              ref="dtRef"
              @change="commonOnChangeEvent"
          >
          </common-range-datepicker>
        </ul>
      </template>
      <template v-slot:R2_C1>
        <label>신청자명</label>
        <ul>
          <input
              ref="reqUserNmRef"
              :placeholder="'신청자명'"
              class="k-textbox w100"
              @keyup.enter="commonOnChangeEvent"
          />
        </ul>
      </template>
      <template v-slot:R2_C2>
        <label>서비스등급</label>
        <ul>
          <kendo-dropdownlist
              ref="svcGradeRef"
              style="width: 100%"
              :data-source="$store.state.codeRequestStore.search.combobox.svcGrade.data.filter(item=>item.cdId!==constant.svcGrade.standard)"
              :data-text-field="'cdNm'"
              :data-value-field="'cdId'"
              :option-label="'전체'"
              @change="commonOnChangeEvent">
          </kendo-dropdownlist>
        </ul>
      </template>
      <template v-slot:R3_C1>
        <label>승인상태</label>
        <ul>
          <kendo-dropdownlist
              ref="apprvStatusRef"
              style="width: 100%"
              :data-source="$store.state.codeRequestStore.search.combobox.apprvStatus.data"
              :data-text-field="'cdNm'"
              :data-value-field="'cdId'"
              :option-label="'전체'"
              @change="commonOnChangeEvent">
          </kendo-dropdownlist>
        </ul>
      </template>
      <template v-slot:R3_C2>
        <label>물류운영방식</label>
        <ul>
          <kendo-dropdownlist
              ref="logiTypeRef"
              style="width: 100%"
              :data-source="$store.state.codeRequestStore.search.combobox.logiType.data"
              :data-text-field="'cdNm'"
              :data-value-field="'cdId'"
              :option-label="'전체'"
              @change="commonOnChangeEvent">
          </kendo-dropdownlist>
        </ul>
      </template>
    </common-area-search>
    <!-- //검색 영역 -->

    <section>
      <div class="contents_top_sec">
        <div class="c_t_button_wrap fl">
          <button @click="$router.push({ name: 'ServiceRequestManageNew' })" class="mid_btn orange mr5 ">수기신청등록</button>
        </div>
        <div class="c_t_button_wrap fr">
          <button class="mid_btn" @click="onClickExcelDownload"><i class="download_icon"></i>엑셀 다운로드</button>
        </div>
      </div>

      <div class="content_list">
        <!-- Kendo 영역 -->
        <kendo-grid ref="gridRef"
                    style="cursor: pointer"
                    :data-source="computedSvcReqGridRows"
                    :navigatable="false"
                    :sortable-mode="'multiple'"
                    :pageable-page-sizes="[5, 10, 20, 50, 100, 500, 1000]"
                    :reorderable="true"
                    :column-menu="false"
                    :resizable="true"
                    :selectable="'row'"
                    :columns="gridColumns"
                    :noRecords="{ template: '<div class=\'gridNoData\' style=\'text-align: center;\'>표시할 데이터가 없습니다.</div>' }"
                    @change="onChangeGrid"
        />
        <!-- Kendo 영역 끝 -->
      </div>
    </section>
  </div>
</template>

<script>
import EtnersCommonUtil from '@ebmp-fe-common-module/module/src/common/etners.common.util'
import EtnersDateTimeUtil from '@ebmp-fe-common-module/module/src/common/etners.datetime.util'
import EtnersEfsUtil from '@/common/etners.efs.util'
import ApiConfig from '@/api/api.config'
import ApiUtil from '@/api/api.util'
import { saveExcel } from '@progress/kendo-vue-excel-export'

export default {
  name: 'ServiceRequestManageList',
  computed: {
    computedSvcReqGridRows: function () {
      const vm = this

      const gridDataSource = new kendo.data.DataSource({
        name: 'gridDataSource',
        page: 1,
        pageSize: 10,
        serverPaging: true,
        serverFiltering: true,
        serverSorting: true,
        transport: {
          read: {
            url: `${ApiConfig.efsDomain}/efs/manage/svc`,
            type: 'GET',
            contentType: 'application/json',
            cache: false,
          },
          parameterMap: function (options, operation) {

            if (operation === 'read') {

              let filters = vm.searchFilterParam()
              let sort = EtnersEfsUtil.convertJSONArrayToJSON('sort', options.sort)

              vm.lastRouteParams = {
                loginUserToken: vm.$store.state.loginStore.lutk,
                skip: options.skip,
                take: options.take,
                page: options.page,
                pageSize: options.pageSize,
                ...sort,
                ...filters
              }
              return vm.lastRouteParams
            }

            if (operation !== 'read' && options.models) {
              return { models: kendo.stringify(options.models) }
            }
          },
        },
        schema: {
          type: 'json',
          parse: function (response) {
            let changeResultData = response.resultData
            let rowNumber = (vm.$refs.gridRef.dataSource.page() - 1)
                * vm.$refs.gridRef.dataSource.pageSize()
            for (let i = 0; i < changeResultData?.length; i++) {
              let row = changeResultData[i]
              // row 번호 셋팅
              row.rowNumber = response.total - rowNumber++
            }
            response.resultData = changeResultData
            return response
          },
          total: function (response) {
            return response.total
          },
          data: function (response) {
            if (response.resultStatus.messageCode === '2000') {
              return response.resultData
            }else if(response.resultStatus.messageCode === '4154'){
              vm.$store.dispatch('LOGOUT').then(() => {
                kendo.alert(response.resultStatus.messageText).bind('close', function() {
                  window.location.href = `/login`
                })
              })
            }else {
              kendo.alert( response.resultStatus.messageText)
              return ''
            }
          },
        },

        error: function (e) {
          console.log('error event handler', e.errors[0])
        },
      })
      return gridDataSource
    },
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      if(from.name === 'ServiceRequestManageDetail'){
        vm.$refs.gridRef.kendoWidget()?.dataSource.read()
      }
    })
  },
  created () {
    const vm = this
  },
  mounted () {},
  methods: {

    cleanSearchDataEvent: function (ev) {
      const refs = this.$refs

      /**
       * 회사명, 신청일자, 신청자명, 서비스등급, 승인상태, 물류운영방식
       * */
      refs.cmpyNmRef.value = ''
      refs.dtRef?.widget().init()
      refs.reqUserNmRef.value = ''
      refs.svcGradeRef.kendoWidget().value('')
      refs.apprvStatusRef.kendoWidget().value('')
      refs.logiTypeRef.kendoWidget().value('')
      this.commonOnChangeEvent()

    },
    searchFilterParam: function () {
      let searchFilterOptions = {}
      const refs = this.$refs

      //회사명
      const cmpyNm = refs.cmpyNmRef.value
      if (cmpyNm) {searchFilterOptions.searchCmpyNm = cmpyNm}

      //신청일자
      const dtRefValue = refs.dtRef.widget().range()
      if (dtRefValue) {
        const searchStartDt = dtRefValue.start
            ? EtnersDateTimeUtil.dateToYYYYMMDD(dtRefValue.start) : ''
        const searchEndDt = dtRefValue.end
            ? EtnersDateTimeUtil.dateToYYYYMMDD(dtRefValue.end) : ''
        searchFilterOptions.searchStartYYYYMMDD = searchStartDt
        searchFilterOptions.searchEndYYYYMMDD = searchEndDt
      }

      //신청자명
      const reqUserNm = refs.reqUserNmRef.value
      if (reqUserNm) {searchFilterOptions.searchReqUserNm = reqUserNm}

      //서비스등급
      const svcGrade = refs.svcGradeRef.kendoWidget().value()
      if (svcGrade) {searchFilterOptions.searchSvcGrade = svcGrade}

      //승인상태
      const apprvStatus = refs.apprvStatusRef.kendoWidget().value()
      if (apprvStatus) {searchFilterOptions.searchApprvStatus = apprvStatus}

      //물류운영방식
      const logiType = refs.logiTypeRef.kendoWidget().value()
      if (logiType) {searchFilterOptions.searchLogiType = logiType}

      return searchFilterOptions ?? undefined
    },
    onChangeGrid (ev) {
      let cellIdx = window.event.target.cellIndex
      let boolForg = window.event.target.className === 'forg' // 키워드 강조 span태그 클릭시에도 deatil로 이동
      if (!isNaN(cellIdx) || boolForg) {
        let selectedRow = ev.sender.select()
        let rowData = ev.sender.dataItem(selectedRow)
        this.$router.push(
            { name: 'ServiceRequestManageDetail',
              params: { svcReqNo : rowData.svcReqNo }
            })
      }
    },
    commonOnChangeEvent: function () {
      const gridRef = this.$refs.gridRef
      gridRef?.kendoWidget()?.dataSource?.query({
        sort: {},
        take: 10,
        page:  1,
        pageSize:  10,
      })
    },
    onClickExcelDownload: function(e) {

      const vm = this
      vm.lastRouteParams.requestType = 'EXPORT'
      ApiUtil.query(`${ApiConfig.efsDomain}/efs/manage/svc`, vm.lastRouteParams).then(response => {

        if (response.data.resultStatus.messageCode === '2000') {
          let resultData = response.data.resultData
          if(EtnersCommonUtil.isNotEmpty(resultData) && resultData.length > 0) {

            let goodsListGridColumns = vm.gridColumns.reduce((acc, cur) => {
              if(cur.field !== 'rowNumber'){ acc.push({ ...cur, width : '120px' }) }
              return acc;
            }, []);

            saveExcel({
              data: resultData,
              fileName: '서비스신청관리목록_' + EtnersDateTimeUtil.dateToYYYYMMDD(new Date()) + '.xlsx',
              columns: goodsListGridColumns
            })
          } else {
            kendo.alert('다운로드 할 자료가 없습니다.')
          }
        } else {
          kendo.alert( response.data.resultStatus.messageText)
          return false
        }
      })
    },
  },
  data () {
    const headerAttributes = { style: 'font-weight: bold; text-align: center;' }
    const centerAttributes = { style: 'text-align: center;' }
    const rightAttributes = { style: 'text-align: right;' }
    return {
      ApiConfig : ApiConfig,
      constant : {
        svcGrade : {
          standard : '01'
        }
      },
      lastRouteParams : {},
      gridColumns: [
        {
          field: 'rowNumber',
          title: 'No',
          width: '3%',
          headerAttributes: headerAttributes,
          attributes: centerAttributes,
          sortable: false
        },
        {
          field: 'cmpyNm',
          title: '회사명',
          width: '15%',
          headerAttributes: headerAttributes,
        },
        {
          field: 'logiTypeNm',
          title: '물류운영방식',
          width: '5%',
          headerAttributes: headerAttributes,
          attributes: centerAttributes
        },
        {
          field: 'mainGoodsText',
          title: '주요운영상품',
          width: '10%',
          headerAttributes: headerAttributes,
          attributes: centerAttributes
        },
        {
          field: 'svcGradeNm',
          title: '서비스등급',
          width: '8%',
          headerAttributes: headerAttributes,
          attributes: centerAttributes
        },
        {
          field: 'addOnYn',
          title: '추가기능신청',
          width: '8%',
          headerAttributes: headerAttributes,
          attributes : centerAttributes
        },
        {
          field: 'expectMonthOutCntNm',
          title: '월 출고건수',
          width: '10%',
          headerAttributes: headerAttributes,
          attributes : centerAttributes
        },
        {
          field: 'svcReqNo',
          title: '신청번호',
          width: '8%',
          headerAttributes: headerAttributes,
          attributes: centerAttributes
        },
        {
          field: 'reqUserNm',
          title: '신청자명',
          width: '8%',
          headerAttributes: headerAttributes,
          attributes: centerAttributes
        },
        {
          field: 'spTelNo',
          title: '연락처',
          width: '8%',
          headerAttributes: headerAttributes,
          attributes: centerAttributes
        },
        {
          field: 'frstRgstDt',
          title: '신청일시',
          width: '8%',
          headerAttributes: headerAttributes,
          attributes: centerAttributes
        },
        {
          field: 'apprvStatusNm',
          title: '승인상태',
          width: '5%',
          headerAttributes: headerAttributes,
          attributes: centerAttributes,
        }
      ],
    }
  },
}
</script>
